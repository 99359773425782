import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export const TeamOrAdminRoute = ({children}) => {
    const { auth } = useAuth();
    const { isAuthenticated } = auth;

    if((auth.isTeam || auth.isAdmin) && isAuthenticated){
        return children
    }
    return (
        <Navigate to='/' />
    );
}

export default TeamOrAdminRoute;