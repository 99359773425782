import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export const AdminRoute = ({children}) => {
    const { auth } = useAuth();
    const { isAuthenticated } = auth;

    if(auth.isAdmin && isAuthenticated){
        return children
    }
    return (
        <Navigate to='/' />
    );
}

export default AdminRoute;